exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-freebies-index-js": () => import("./../../../src/pages/freebies/index.js" /* webpackChunkName: "component---src-pages-freebies-index-js" */),
  "component---src-pages-graphics-index-js": () => import("./../../../src/pages/graphics/index.js" /* webpackChunkName: "component---src-pages-graphics-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-licenses-js": () => import("./../../../src/pages/product-licenses.js" /* webpackChunkName: "component---src-pages-product-licenses-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-terms-privacy-js": () => import("./../../../src/pages/terms-privacy.js" /* webpackChunkName: "component---src-pages-terms-privacy-js" */),
  "component---src-templates-freebie-details-js": () => import("./../../../src/templates/freebie-details.js" /* webpackChunkName: "component---src-templates-freebie-details-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

